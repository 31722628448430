import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'react-styled-flexboxgrid';
import PowerswitchLogo from '../images/powerswitchlogo.svg';
import { gridConfig } from '../util/GlobalStyles';

function Header() {
  return (
    <Wrapper>
      <Row center="sm" between="xs">
        <Col xs={12} sm={8}>
          <Logo src={PowerswitchLogo} alt="logo" />
        </Col>
      </Row>
    </Wrapper>
  );
}

export default Header;

const Wrapper = styled.div`
  padding: 12px 0;
  margin: 40px 16px;
  @media (max-width: ${gridConfig.breakpoints.md}em) {
    margin: 40px 0 24px 0;
  }
`;

const Logo = styled.img`
  width: 320px;
  @media (max-width: ${gridConfig.breakpoints.md}em) {
    width: 240px;
  }
`;
