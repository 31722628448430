import styled from 'styled-components';

const CardTitle = styled.h2`
  font-weight: 400;
  color: #737373;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.69;
  margin-bottom: 36px;
  font-family: overpass;
`;

export default CardTitle;
