import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Grid } from 'react-styled-flexboxgrid';
import { Helmet } from 'react-helmet';
import GlobalStyles from '../util/GlobalStyles';
import 'normalize.css';
import Header from '../layout/Header';
import Feed from '../layout/Feed';
import Footer from '../layout/Footer';
import About from '../layout/About';
import Contact from '../layout/Contact';

const gridTheme = {
  flexboxgrid: {
    outerMargin: 1,
  },
};

const IndexPage = () => (
  <>
    <GlobalStyles />
    <ThemeProvider theme={gridTheme}>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="Powerswitch Photography freelance automotive fotograaf" />
        <meta name="title" content="Powerswitch Photography" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link href="https://fonts.googleapis.com/css2?family=Chivo:wght@300;400;700;900&family=Overpass:ital,wght@0,100;0,200;0,300;0,400;0,600;0,700;0,900;1,800&display=swap" rel="stylesheet" />
        <title>Powerswitch Photography</title>
      </Helmet>
      <Grid>
        <Header />
        <Feed />
        <About />
        <Contact />
      </Grid>
      <Footer />
    </ThemeProvider>
  </>
);

export default IndexPage;
