import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

export default function LightWidget() {
  return (
    <>
      <Helmet>
        <script src="https://cdn.lightwidget.com/widgets/lightwidget.js" />
      </Helmet>
      <Frame
        title="lightwidget"
        src="//lightwidget.com/widgets/4954ffac8bfd50f28a0e1ced210e5309.html"
        scrolling="no"
        allowtransparency="true"
        className="lightwidget-widget"
      />
    </>
  );
}

const Frame = styled.iframe`
  width:100%;
  border:0;
  overflow:hidden;
`;
