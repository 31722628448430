import React from 'react';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import Follow from '../components/Follow';
import { gridConfig } from '../util/GlobalStyles';
import PowerswitchLogo from '../images/powerswitchlogo.svg';

function Footer() {
  return (
    <Wrapper>
      <Grid>
        <Row>
          <Col xs={12} sm={8}>
            <Logo alt="Logo" src={PowerswitchLogo} />
            <Follow />
          </Col>
          <Col xs={12} sm={4}>
            <Address>Hasseltsesteenweg 581</Address>
            <Address>3700 Tongeren</Address>
            <Address>BE0542 528 621</Address>
            <Spacer />
            <Address>
              <Link href="mailto:info@powerswitchphotography.com">
                info@powerswitchphotography.com
              </Link>
            </Address>
            <Address>+32 (0)4 86 83 95 27</Address>
          </Col>
        </Row>
      </Grid>
    </Wrapper>
  );
}

export default Footer;

const Link = styled.a`
  color: #fdfdfd;
`;

const Wrapper = styled.footer`
  margin-top: -80px;
  padding: 120px 0 80px 0;
  background: #1a1a1a;
  color: #fefefe;
  font-size: 14px;
  @media (max-width: ${gridConfig.breakpoints.md}em) {
    margin-top: 24px;
    padding: 40px 24px;
  }
`;

const Address = styled.address`
  font-style: normal;
  font-weight: 300;
  color: #fefefe;
  font-weight: 400;
  line-height: 1.5;
`;

const Spacer = styled.div`
  height: 24px;
`;

const Logo = styled.img`
  width: 230px;
  margin-bottom: 32px;
`;
