import { config } from 'react-styled-flexboxgrid';
import { createGlobalStyle } from 'styled-components';

export const gridConfig = config({});

export default createGlobalStyle`
  html {
    box-sizing: border-box;
    font-family: 'Overpass', sans-serif;
  }

  body {
    background-color: #141414;
    font-family: 'Overpass';
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Chivo', sans-serif;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  a[href^=tel] {
   text-decoration:inherit;
   color: inherit;
  }
`;
