import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  padding: .8rem 0;
  margin-bottom: 2rem;
  border: none;
  border-bottom: 1.5px solid rgba(0,0,0,.6);
  background-color: ${({ theme }) => theme.white};
  &::placeholder {
    font-size: 16px;
  }
  &:focus {
    border-color: #80bdff;
    outline: 0;
    border-bottom: 2px solid rgba(0,0,0,.9);
  }
`;

const Label = styled.label`
  font-weight: 300;
  margin-bottom: 0.5rem;
  width: 100%;
`;

export default ({
  label, type, textarea, ...props
}) => (
  <Wrapper>
    <Label>{label}</Label>
    <Input htmlFor={label} type={type} {...props} />
  </Wrapper>
);
