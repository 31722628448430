import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import CardTitle from '../components/CardTitle';
import Sven from '../images/sven2.jpeg';
import { gridConfig } from '../util/GlobalStyles';

function About() {
  function getAge() {
    const ageDifMs = Date.now() - new Date('1990-11-12');
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  return (
    <Row>
      <Col xs={12} lg={9}>
        <Wrapper>
          <ContentWrapper>
            <Image alt="foto van Sven" src={Sven} />
            <Content>
              <CardTitle>Hallo! Ik ben Sven.</CardTitle>
              <Text>
                {getAge()}
                {' '}
                jaar, geboren en getogen in de oudste stad van België,
                Tongeren en autoliefhebber sinds mijn geboorte.
                <br />
                <br />
                Rond mijn 17 jaar begon ik als autospotter wagens te
                fotograferen. In 2013 kreeg ik mijn eerste opdracht aangeboden
                en sindsdien ben ik freelance fotograaf, specifiek gericht op de
                automotive sector.
                <br />
                <br />
                Dankzij heel wat jaren experimenteren heb ik mijn eigen
                fotografiestijl kunnen creëren. Dit heeft geleid tot heel wat
                leuke samenwerkingen met o.a. Ferrari-Maserati Antwerp,
                Mercedes-Benz groep JAM, Mercedes Claes & Zonen, BMW Davo,
                Polywax, ProSpeed Engineering, Groep Boden,…
              </Text>
            </Content>
          </ContentWrapper>
        </Wrapper>
      </Col>
    </Row>
  );
}

export default About;

const Wrapper = styled.div`
  padding: 40px 0;
  margin: 40px 0;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  @media (max-width: ${gridConfig.breakpoints.md}em) {
    flex-direction: column;
  }
`;

const Content = styled.div`
  width: 60%;
  background-color: #fff;
  color: #000;
  padding: 80px;
  height: 600px;
  @media (max-width: ${gridConfig.breakpoints.md}em) {
    width: 100%;
    height: auto;
    padding: 32px;
  }
`;

const Image = styled.img`
  height: 600px;
  @media (max-width: ${gridConfig.breakpoints.md}em) {
    width: 100%;
    height: auto;
  }
`;

const Text = styled.p`
  font-size: 15px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.4;
`;
