import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'react-styled-flexboxgrid';
import Input from '../components/Input';
import CardTitle from '../components/CardTitle';
import ArrowRight from '../images/arrow-right.svg';
import Sven from '../images/sven.png';
import { gridConfig } from '../util/GlobalStyles';

function Contact() {
  return (
    <Row>
      <Col xs={12}>
        <ContentWrapper>
          <Image alt="foto van Sven" src={Sven} />
          <Content>
            <CardTitle>Samenwerken?</CardTitle>
            Laat je telefoonnummer achter,
            <br />
            dan contacteer ik jou.
            <SmallText>
              Of stuur een mailtje naar
              {' '}
              <b>info@powerswitchphotography.com</b>
            </SmallText>
            <form
              action="https://getform.io/f/c08aba9e-6d6a-41a3-9a8a-55731a547a3c"
              method="POST"
            >
              <Input
                required
                placeholder="Telefoonnummer"
                type="phone"
                name="phone"
              />
              <CardButton>Versturen</CardButton>
            </form>
          </Content>
        </ContentWrapper>
      </Col>
    </Row>
  );
}

export default Contact;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 460px;
  margin: 40px 0;
  @media (max-width: ${gridConfig.breakpoints.md}em) {
    flex-direction: column-reverse;
    height: auto;
    width: 100%;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  height: 100%;
  padding: 80px 80px 120px 80px;
  width: 600px;
  right: 0;
  font-size: 20px;
  line-height: 1.3;
  position: relative;
  @media (max-width: ${gridConfig.breakpoints.md}em) {
    width: 100%;
    padding: 32px;
  }
`;

const Image = styled.img`
  z-index: 10;
  object-fit: contain;
  height: 100%;
  @media (max-width: ${gridConfig.breakpoints.md}em) {
    width: 100%;
  }
`;

const SmallText = styled.p`
  font-size: 13px;
  margin-top: 24px;
`;

const CardButton = styled.button`
  z-index: 20;
  position: absolute;
  right: 0px;
  color: #fff;
  border: 0;
  text-transform: uppercase;
  font-size: 16px;
  background-size: 24px;
  background-image: url(${ArrowRight});
  background-repeat: no-repeat;
  background-color: #000;
  background-position-y: center;
  background-position-x: 90%;
  padding: 18px 80px 18px 32px;
  font-family: overpass;
  font-weight: 500;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;
