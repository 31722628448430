import React from 'react';
import styled from 'styled-components';
import FacebookIcon from '../images/facebook.svg';
import WhatsappIcon from '../images/whatsapp.svg';
import InstagramIcon from '../images/instagram.svg';

export default function Follow() {
  return (
    <Wrapper>
      <Link target="_blank" href="https://facebook.com/powerswitchphotography">
        <Icon src={FacebookIcon} alt="Facebook" />
      </Link>
      <Link target="_blank" href="https://wa.me/+32486839527">
        <Icon src={WhatsappIcon} alt="Whatsapp" />
      </Link>
      <Link target="_blank" href="https://www.instagram.com/powerswitchphotography/">
        <Icon src={InstagramIcon} alt="Instagram" />
      </Link>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  margin: 24px 0;
`;

const Icon = styled.img`
  height: 24px;
`;

const Link = styled.a`
  padding-right: 12px;
`;
