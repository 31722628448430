import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import LightWidget from '../components/LightWidget';

function Feed() {
  return (
    <Row>
      <Col xs={12}>
        <LightWidget />
      </Col>
    </Row>
  );
}

export default Feed;
